@charset "UTF-8";

.tab_comm .list_tab {
  height: 50px;
  border-bottom: 1px solid #e1e1e1;
}
.tab_comm .list_tab:after {
  display: block;
  visibility: hidden;
  height: 0;
  font-size: 0;
  clear: both;
  content: "";
}
.tab_comm .list_tab li {
  float: left;
  min-width: 140px;
  height: 48px;
  border-top: 2px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  background-color: #fbfbfb;
}
.tab_comm .list_tab .link_tab {
  display: block;
  padding: 0 32px;
  line-height: 48px;
  font-size: 16px;
  color: #888;
  text-align: center;
}
.tab_comm .list_tab li:hover {
  border-top-color: #bbb;
}
.tab_comm .list_tab li:hover .link_tab {
  color: #555;
}
.tab_comm .list_tab .on,
.tab_comm .list_tab .on:hover {
  border-top-color: #222;
  border-bottom: 1px solid #fff;
  background-color: #fff;
}
.tab_comm .list_tab .on .link_tab,
.tab_comm .list_tab .on:hover .link_tab {
  color: #555;
  font-weight: 500;
}
.tab_comm ~ .area_filter .box_filter,
.tab_comm ~ div .area_filter .box_filter {
  border-top: 0 none;
}
.tab_comm /deep/ ~ .area_filter .box_filter,
.tab_comm /deep/ ~ div .area_filter .box_filter {
  border-top: 0 none;
}
